import { v4 as uuidv4 } from "uuid";
import { database } from "./Api";

export class ConversationService {
  checkConversationExistence = async (code) => {
    const doc = await database.collection("/rooms").doc(code).get();

    if (doc.exists) {
      return doc.data();
    }

    return null;
  };

  getConversationMessages = (code) => {
    return database
      .collection("/conversations")
      .where("room", "==", code)
      .orderBy("createdAt");
  };

  getApprovedConversationMessages = (code) => {
    return database
      .collection("/conversations")
      .where("room", "==", code)
      .where("visible", "==", true)
      .orderBy("createdAt");
  };

  approveMessage = async (message) => {
    await database
      .collection("/conversations")
      .doc(message.id)
      .update({ visible: true, isNew: false });
  };

  blockMessage = async (message) => {
    await database
      .collection("/conversations")
      .doc(message.id)
      .update({ visible: false, isNew: false });
  };

  deleteMessage = async (message) => {
    await database.collection("/conversations").doc(message.id).delete();
  };

  updateRoomName = async (name, roomCode) => {
    await database.collection("rooms").doc(roomCode).update({ name: name });
  };

  createRoom = async (room) => {
    await database.collection("rooms").doc(room.code).set({
      code: room.code,
      key: room.key,
      name: room.name,
      userId: room.userId,
    });
  };

  deleteRoom = async (room) => {
    await database.collection("rooms").doc(room.code).delete();
    const docs = await database
      .collection("conversations")
      .where("room", "==", room.code)
      .get();

    if (docs.empty) {
      return;
    }

    docs.forEach((doc) => {
      doc.ref.delete();
    });
  };

  sendMessage = async (message, code, userId) => {
    await database.collection("/conversations").add({
      key: uuidv4(),
      userId: userId,
      isNew: true,
      room: code,
      value: message,
      visible: false,
      createdAt: new Date().getTime(),
    });
  };
}
