import React from "react";
import { Row } from "antd";
import { useHomeController } from "../../hooks/home-controller";
import DownloadOnAppStoreLogo from "../../assets/img/download-on-app-store-pt-br-black.svg";
import DownloadOnPlayStoreLogo from "../../assets/img/google-badge.png";
import {
  LayoutStyled,
  HeaderStyled,
  Title,
  LoginButton,
  ContentStyled,
  ColStyled,
  Label,
  CodeInput,
  JoinRoomButton,
  PrivacyPolicyButton,
  StoreBadgeLink,
  StoreBadgeImage,
} from "./style";

export const HomePage = () => {
  const {
    code,
    setCode,
    enterToConversation,
    loading,
    disableEnterButton,
    goToLoginPage,
    goToPrivacyPolicy,
  } = useHomeController();

  return (
    <LayoutStyled>
      <HeaderStyled>
        <Title>Participe da Conversa</Title>
        <LoginButton type="primary" size="small" onClick={goToLoginPage}>
          Login
        </LoginButton>
      </HeaderStyled>
      <ContentStyled>
        <Row justify={"center"}>
          <ColStyled xs={24} sm={24} md={20} lg={18} xl={16} xxl={10}>
            <Label>Digite o código da conversa</Label>
            <CodeInput
              placeholder="Código"
              value={code}
              onChange={(event) =>
                setCode(event.target.value.trim().toLowerCase())
              }
            />
            <JoinRoomButton
              type="primary"
              block
              onClick={enterToConversation}
              loading={loading}
              disabled={disableEnterButton()}
            >
              Entrar
            </JoinRoomButton>
            {/*<StoreBadgeLink href="https://apps.apple.com/us/app/part%C3%ADcipe-da-conversa/id1573039271">*/}
            {/*  <StoreBadgeImage*/}
            {/*    alt="Baixar na App Store"*/}
            {/*    src={DownloadOnAppStoreLogo}*/}
            {/*  />*/}
            {/*</StoreBadgeLink>*/}
            {/*<StoreBadgeLink href="https://play.google.com/store/apps/details?id=com.jtc_native&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">*/}
            {/*  <StoreBadgeImage*/}
            {/*    alt="Disponível no Google Play"*/}
            {/*    src={DownloadOnPlayStoreLogo}*/}
            {/*  />*/}
            {/*</StoreBadgeLink>*/}
            <PrivacyPolicyButton type="link" block onClick={goToPrivacyPolicy}>
              Política de Privacidade
            </PrivacyPolicyButton>
          </ColStyled>
        </Row>
      </ContentStyled>
    </LayoutStyled>
  );
};
