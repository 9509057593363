import React from "react";
import { ConversationService } from "../../services/Conversation";

export const ConversationServiceContext = React.createContext();

const conversationService = new ConversationService();

export const ConversationProvider = ({ children }) => {
  return (
    <ConversationServiceContext.Provider value={{ conversationService }}>
      {children}
    </ConversationServiceContext.Provider>
  );
};
