import { RouterComponent } from "./Router";
import { AuthProvider } from "./providers/auth-provider";
import { AnonymousUserProvider } from "./providers/anonymous-user-provider";
import { ConversationProvider } from "./providers/conversation-provider";
import { RoomProvider } from "./providers/room-provider";
import { ThemeProvider } from "styled-components";
import "antd/dist/antd.css";
import theme from "./assets/theme";
import GlobalStyle from "./assets/global-style";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AuthProvider>
        <AnonymousUserProvider>
          <ConversationProvider>
            <RoomProvider>
              <RouterComponent />
            </RoomProvider>
          </ConversationProvider>
        </AnonymousUserProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
