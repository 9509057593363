import React, { useContext, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { HomePage } from "./pages/home";
import { AuthContext } from "./providers/auth-provider";

const ConversationPage = lazy(() => import("./pages/conversation/index"));
const RoomSelection = lazy(() => import("./pages/room-selection/index"));
const RoomManagementPage = lazy(() => import("./pages/room-management/index"));
const CreateRoomPage = lazy(() => import("./pages/create-room/index"));
const LoginPage = lazy(() => import("./pages/login"));
const FollowConversation = lazy(() =>
  import("./pages/follow-conversation/index")
);
const PrivacyPolicy = lazy(() => import("./pages/privacy-policy/index"));
const ContactUs = lazy(() => import("./pages/contact-us/index"));

export const RouterComponent = () => {
  return (
    <Router>
      <Suspense fallback={<div>Carregando...</div>}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/conversa/:code" component={ConversationPage} />
          <Route
            exact
            path={"/conversa/:code/ver"}
            component={FollowConversation}
          />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/contact-us" component={ContactUs} />
          <PrivateRoute exact path="/room-selection">
            <RoomSelection />
          </PrivateRoute>
          <PrivateRoute exact path="/room-management">
            <RoomManagementPage />
          </PrivateRoute>
          <PrivateRoute exact path="/room-creation">
            <CreateRoomPage />
          </PrivateRoute>
        </Switch>
      </Suspense>
    </Router>
  );
};

const PrivateRoute = ({ children, ...rest }) => {
  const { loggedInUser } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedInUser && loggedInUser.email ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
