import styled from "styled-components";
import { Layout, Button, Col, Input } from "antd";

const LayoutStyled = styled(Layout)`
  min-height: 100vh;
  height: 100%;
`;

const HeaderStyled = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 25px;
`;

const Title = styled.h1`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: auto 0 auto 0;

  @media screen and (max-width: 375px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

const LoginButton = styled(Button)`
  margin: auto 0 auto 0;
`;

const ColStyled = styled(Col)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ContentStyled = styled(Layout.Content)``;

const Label = styled.p`
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin: 3rem 1rem 1rem;

  @media screen and (max-width: 375px) {
    font-size: 1rem;
    line-height: 1rem;
  }
`;

const CodeInput = styled(Input)`
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
`;

const JoinRoomButton = styled(Button)`
  height: 5rem;
  margin: 2rem 1rem;
  font-size: 2rem;
`;

const PrivacyPolicyButton = styled(Button)``;

const StoreBadgeLink = styled.a`
  width: 100%;
  max-width: 646px;
  padding: 0 1rem;
  justify-content: center;
  margin-bottom: 1rem;
`;

const StoreBadgeImage = styled.img`
  width: 100%;
`;

export {
  LayoutStyled,
  HeaderStyled,
  Title,
  LoginButton,
  ContentStyled,
  ColStyled,
  Label,
  CodeInput,
  JoinRoomButton,
  PrivacyPolicyButton,
  StoreBadgeLink,
  StoreBadgeImage,
};
