import { database } from "./Api";

export class RoomService {
  getRoomsList = async (userId) => {
    const list = await database
      .collection("/rooms")
      .where("userId", "==", userId)
      .get();

    return list;
  };
}
