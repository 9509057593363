import React from "react";
import { v4 as uuidv4 } from "uuid";

export const AnonymousUserIdContext = React.createContext();

export const AnonymousUserProvider = ({ children }) => {
  return (
    <AnonymousUserIdContext.Provider value={uuidv4()}>
      {children}
    </AnonymousUserIdContext.Provider>
  );
};
