import React from "react";
import { RoomService } from "../../services/Room";

export const RoomServiceContext = React.createContext();

const roomService = new RoomService();

export const RoomProvider = ({ children }) => {
  return (
    <RoomServiceContext.Provider value={{ roomService }}>
      {children}
    </RoomServiceContext.Provider>
  );
};
