import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ConversationServiceContext } from "../../providers/conversation-provider";
import { message as notification } from "antd";

export const useHomeController = () => {
  const { conversationService } = useContext(ConversationServiceContext);
  const history = useHistory();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const disableEnterButton = () => {
    return code.trim().length === 0;
  };

  const goToLoginPage = () => {
    history.push("/login");
  };

  const goToPrivacyPolicy = () => {
    history.push("/privacy-policy");
  };

  const enterToConversation = async () => {
    try {
      setLoading(true);
      const result = await conversationService.checkConversationExistence(code);

      if (result) {
        setLoading(false);
        history.push(`/conversa/${code}`);
        return;
      }

      notification.warn(
        "Essa sala não existe, verifique o código digitado e tente novamente."
      );

      setLoading(false);
      console.log("essa sala não existe");
    } catch (error) {
      notification.error("Erro ao entrar na sala, tente novamente mais tarde.");
      console.log(error);
      setLoading(false);
    }
  };

  return {
    code,
    setCode,
    enterToConversation,
    loading,
    disableEnterButton,
    goToLoginPage,
    goToPrivacyPolicy,
  };
};
