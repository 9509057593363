import React, { useEffect, useState } from "react";
import { auth } from "../../services/Api";
import { Auth } from "../../services/Auth";

export const AuthContext = React.createContext();

const authService = new Auth();

export const AuthProvider = ({ children }) => {
  let unsubscribe;
  const [loggedInUser, setLoggedInUser] = useState();

  useEffect(() => {
    unsubscribe = auth.onAuthStateChanged((user) => {
      setLoggedInUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ loggedInUser, authService }}>
      {children}
    </AuthContext.Provider>
  );
};
