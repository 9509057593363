import { auth } from "./Api";

export class Auth {
  loginWithEmailAndPassword = async (email, password) => {
    const user = await auth.signInWithEmailAndPassword(email, password);

    if (!user) {
      throw new Error("authentication error");
    }
  };

  logout = async () => {
    await auth.signOut();
  };
}
